import React from 'react';
import styled from 'styled-components';
import benefits from '../assets/images/benefits.png';

import layoutStyle from '../styles/layout';
import { contentHeaderStyles } from '../styles/text';
import { media } from '../styles/constants';
import { padding } from '../styles/layout';

const PartnerSection = styled.div`
  ${layoutStyle};
  ${contentHeaderStyles};

  h2 {
    text-align: center;
    font-size: 2.5rem;
  }

  box-sizing: border-box;
  overflow: hidden;
  padding: 64px 0;
  background: white;

  @media (min-width: ${media.bigDesktop}px) {
    max-width: 100%;
    padding: ${padding.desktop};
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 100%;

    @media (min-width: ${media.tabletLandscape}px) {
      width: 60%;
    }
  }
`;

function Benefits() {
  return (
    <PartnerSection>
      <h2>Benefits</h2>
      <Wrapper>
        <img src={benefits} />
      </Wrapper>
    </PartnerSection>
  );
}

export default Benefits;
