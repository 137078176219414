import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import layoutStyle from '../styles/layout';
import { colors, media } from '../styles/constants';

import Layout from '../components/layout';
import SEO from '../components/seo';
import UseCasesComponent from '../components/useCases';
import PropTypes from 'prop-types';
import ContactForm from '../components/contactForm';
import Benefits from '../components/benefits';

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  ${layoutStyle}

  @media (min-width: ${media.tabletPortrait}px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  > div:first-child {
    flex: 1 0 100%;
    width: 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      margin-right: 4rem;
      flex: 1 0 65%;
    }
  }

  hr {
    border: solid 1px ${colors.blue};
    border-radius: 5px;
    opacity: 0.5;
    max-width: 40rem;
    width: 100%;
    margin: 2rem 0;

    @media (min-width: ${media.tabletPortrait}px) {
      display: none;
    }
  }

  > div:last-child {
    flex: 1 0 50%;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      flex: 0 1 45%;
    }

    h3 {
      line-height: 1.2;
      text-align: center;
      color: ${colors.blue};
      font-size: 1.5rem;
    }

    span {
      font-size: 1.25rem;
    }
  }
`;

function UseCases({ location: { pathname } }) {
  const data = useStaticQuery(graphql`
    query {
      containers: file(relativePath: { eq: "containers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      wasteManagement: file(relativePath: { eq: "waste-management.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      workflow: file(relativePath: { eq: "workflow.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pedestrian: file(relativePath: { eq: "pedestrian.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Use Cases" pathname={pathname} />
      <Layout>
        <UseCasesComponent
          list={[
            {
              title: 'Returnable Containers',
              bullets: [
                'Insufficient visibility into inventory can have a profound impact on the smooth functioning of logistics and overall business operations. To address this challenge, our innovative solution for returnable container inventory provides a streamlined approach to effectively manage and track your inventory of returnable containers, including beer kegs, gas bottles, pallets and more.',
                'By equipping each container with active tags, you unlock real-time visibility and increase control. This system empowers you to manage average round-trip times, delivery durations, and automate labor-intensive picking tasks. Moreover, the reading distance capability guarantees accurate asset visibility, even in the most industrial environments.'
              ],
              image: data.containers.childImageSharp.fluid
            },
            {
              title: 'Pedestrian Safety',
              bullets: [
                'Our Tugger Train Tracking System goes beyond improving efficiency and productivity; it also plays a crucial role in enhancing workplace safety. By tracking the movement of tugger trains, we provide real-time visibility that helps prevent potential accidents and promote a safer working environment.',
                'Through the use of wearable tags carried by workers, our system establishes a communication link that sends signals to the tugger trains, enabling proactive accident prevention and ensuring the safety of both workers and vehicles. The system offers the following safety benefits, such as collision avoidance, a system that detects the proximity of workers within the operational area. Providing real-time alerts and warnings enables operators to take immediate action and avoid potential collisions.'
              ],
              image: data.pedestrian.childImageSharp.fluid
            },
            {
              title: 'Workflow Management',
              bullets: [
                'Our innovative tracking system extends beyond the tugger train system application to encompass work-in-process tracking in industrial facilities. By strategically installing readers at key points within the factory and attaching tags to the boxes or containers carrying the goods or materials being produced, we enable precise measurement and monitoring of their movement.',
                'This automated process brings several benefits, including reduced human errors and costs, real-time visibility into location and status, enhanced traceability and accountability, identifying bottlenecks, minimizing delays or quality issues, and streamlined workflow with increased operational efficiency, all without manual intervention.'
              ],
              image: data.workflow.childImageSharp.fluid
            },
            {
              title: 'Smart Cities',
              bullets: [
                'This solution for smart city waste management revolutionizes the way containers are tracked throughout the city. By utilizing mobile anchors installed on garbage trucks, we enable real-time tracking of waste bin locations. The tags used are specifically adapted to automatically provide information on when and where a bin is disposed of. This allows for efficient bin management and timely unload detection. ',
                'Leveraging waste management analytics, our system provides valuable insights to optimize truck routes and strategically place containers. The result is an enhanced waste management process that improves operational efficiency and promotes effective resource allocation within the city.'
              ],
              image: data.wasteManagement.childImageSharp.fluid
            }
          ]}
        />
        <Benefits />
        <FormContainer>
          <ContactForm title="Contact Us" page="Use Cases" />
          <hr />
        </FormContainer>
      </Layout>
    </>
  );
}

UseCases.propTypes = {
  location: PropTypes.object.isRequired
};

export default UseCases;
